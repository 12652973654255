<template>
  <header class="bg-[#9ED4F3] grid h-screen first_header place-items-center">
    <div class="-translate-y-5">
      <div class="grid h-10 justify-items-center text-3xl ">
        <h1 class="">Martin danas ima:</h1>
        <h1>
          <AnimatedNumber :value="days"></AnimatedNumber> dana
        </h1>
        <h1>
          <AnimatedNumber :value="weeks"></AnimatedNumber> tjedana
        </h1>
        <h1>
          <AnimatedNumber :value="months"></AnimatedNumber> mjeseci
        </h1>
        <div class="flex">
          <div class="w-96">
            <AnimatedProgressBar :value="percentMonth" :from="months" label="mj."></AnimatedProgressBar>
          </div>
        </div>
        <h1>
          <AnimatedNumber :value="years"></AnimatedNumber> Godina
        </h1>
        <div class="flex">
          <div class="w-96">
            <AnimatedProgressBar :value="percentYear" :from="years" label="god."></AnimatedProgressBar>
          </div>
        </div>
        <span class="text-xl">Martin je rođen {{ dob.format('DD.MM.YYYY HH:mm') }}</span>
      </div>
    </div>
  </header>
</template>

<script>

import moment from "moment";
import AnimatedNumber from '@/components/AnimatedNumber.vue';
import AnimatedProgressBar from '@/components/AnimatedProgressBar.vue';


export default {
  name: 'App',
  components: {
    AnimatedNumber,
    AnimatedProgressBar

  },
  data() {
    return {
      dob: moment('21-09-2022 19:23:00', 'DD.MM.YYYY HH:mm'),
      weeks: 0,
      days: 0,
      months: 0,
      years: 0,
      percentMonth: 0,
    }
  },
  methods: {
    startCounting() {
      this.weeks = Math.ceil(moment.duration(new Date() - this.dob).asWeeks());
      this.days = Math.ceil(moment.duration(new Date() - this.dob).asDays());
      this.months = Math.floor(moment.duration(new Date() - this.dob).asMonths());
      this.years = Math.floor(moment.duration(new Date() - this.dob).asYears());
      this.percentMonth = (moment.duration(new Date() - this.dob).asMonths() - this.months) * 100;
      this.percentYear = (moment.duration(new Date() - this.dob).asYears() - this.years) * 100;

    }
  },
  created() {
    setInterval(this.startCounting, 200);
  }
}
</script>

<style scoped>
body {
  background-color: rgb(164, 214, 245);
}

.first_header {
  background-image: url("assets/bg1.png");
  background-repeat: no-repeat;
  background-position: center;
}
</style>
