<template>
    <span>{{ displayValue }}</span>
  </template>
  
  <script>
  import gsap from "gsap";
  export default {
    name: "AnimatedNumber",
    props: ["value"],
    components: {},
    watch: {
      value() {
        gsap.to(this, {
          tweenValue: this.value,
          onUpdate: () => {
            this.displayValue = Math.ceil(this.tweenValue);
          },
        }).duration(2);
      },
    },
    data() {
      return {
        displayValue: this.value,
        tweenValue: this.value,
      };
    },
  };
  </script>
  
  <style>
  </style>