<template>
    
    <div>
        <div class="flex justify-between">
        <span class="text-sm">
            {{ this.from }} {{ this.label }}
        </span>
        <span class="text-sm">
            {{ this.from + 1 }} {{ this.label }}
        </span>

        </div>
        <div class="meter animate nostripes">
            <span :style="{ width: displayValue + '%' }"></span>
        </div>
    </div>
</template>


<script>
import gsap from "gsap";
export default {
    name: "AnimatedNumber",
    props: ["value", "from","label"],
    components: {},
    watch: {
        value() {
            gsap.to(this, {
                tweenValue: this.value,
                onUpdate: () => {
                    this.displayValue = Math.ceil(this.tweenValue);
                },
            }).duration(1);
        },
    },
    data() {
        return {
            displayValue: this.value,
            tweenValue: this.value,
        };
    },
};

</script>
<style scoped>
.from{
    font-size:18px;
}
.meter {
    box-sizing: content-box;
    height: 10px;
    position: relative;
    background: rgb(164, 214, 245);
    border-radius: 1px;
    padding: 7px;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.meter>span {
    display: block;
    height: 100%;
    background-color: rgb(35, 139, 184);
    background-image: linear-gradient(center bottom,
            rgb(35, 139, 184) 37%,
            rgb(35, 139, 184) 69%);
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
        inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
}

.meter>span:after,
.animate>span>span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(-45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent);
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

.animate>span:after {
    display: none;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 50px 50px;
    }
}

.orange>span {
    background-image: linear-gradient(#f1a165, #f36d0a);
}

.red>span {
    background-image: linear-gradient(#f0a3a3, #f42323);
}

.nostripes>span>span,
.nostripes>span::after {
    background-image: none;
}
</style>